import './App.css';
import { useState } from "react";
import { TextInput } from "./components/text-input";
import { TextArea } from "./components/text-area";
import { Inline } from "./components/inline/inline";
import {FormGroup} from "./components/form-group/form-group";
import {Multiselect} from "./components/multiselect/multiselect";
import navLogo from './nav-logo.png'
import axios from "axios";
import {HealthQuestionnaire} from "./questions/health_questionnaire";
import {PilatesInformationConsent} from "./questions/pilates_information_consent";
import {PrivacyNoticeConsent} from "./questions/privacy_notice_consent";
import {Settings} from "./helpers/settings";

function UpdateApp() {
    const [form, setForm] = useState({update: true})

    const [submitting, setSubmitting] = useState(false)
    const [error, setError] = useState(null)
    const [submitted, setSubmitted] = useState(false)

    const updateForm = (key, value) => {
        setError(null)
        setForm({...form, [key]: value})
    }

    const currentValue = (name) => form[name]

    const valueContains = (name, value) => {
        return currentValue(name) ? currentValue(name).includes(value) : false
    }

    const emailValidation = (value) => /^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(value)
    const numericalInputValidator = (value) => (/^[+ \d]+$/.test(value) || value === '')

    return (
        <>
            <div className={"form-container"}>
                <nav>
                    <h1>EMS Pilates Update Form</h1>
                    <img src={navLogo} alt={'logo'} width={80}/>
                </nav>
                <p>
                    Please complete this update form to ensure that EMS Pilates has accurate and your up to date contact
                    information and medical history. Information is treated confidentially. <a href="https://static.emspilates.co.uk/participants-privacy-notice.pdf" target="_blank">
                        (Click here to see EMS Pilates participant privacy notice)
                    </a>
                </p>
                <FormGroup
                    questionTitle={'Contact Details'}
                    children={
                        [
                            <Inline
                                key={"contact.details.name"}
                                children={
                                    [
                                        <TextInput
                                            key={"firstName"}
                                            name="firstName"
                                            title="First Name"
                                            value={currentValue}
                                            onChange={updateForm}
                                            weight={3}
                                        />,
                                        <TextInput
                                            key={"lastName"}
                                            name="lastName"
                                            title="Last Name"
                                            value={currentValue}
                                            onChange={updateForm}
                                            weight={3}
                                        />
                                    ]
                                }
                            />,
                            <TextInput
                                key={"email"}
                                name="email"
                                title="Email"
                                value={currentValue}
                                onChange={updateForm}
                                isValid={emailValidation}
                            />,

                        ]
                    }
                />
                <FormGroup
                    questionTitle={"Telephone"}
                    children={
                        [
                            <TextInput
                                key={"telMobile"}
                                name="telMobile"
                                title="Mobile"
                                value={currentValue}
                                onChange={updateForm}
                                validateChange={numericalInputValidator}
                            />,
                            <TextInput
                                key={"telHome"}
                                name="telHome"
                                title="Home"
                                value={currentValue}
                                onChange={updateForm}
                                validateChange={numericalInputValidator}
                            />
                        ]
                    }
                />
                <FormGroup
                    questionTitle={"Emergency Contact"}
                    children={
                        [
                            <TextInput
                                key={"emergencyContactName"}
                                name="emergencyContactName"
                                title="Name"
                                value={currentValue}
                                onChange={updateForm}
                            />,
                            <TextInput
                                key={"emergencyContactHomeNumber"}
                                name="emergencyContactHomeNumber"
                                title="Home Telephone Number"
                                value={currentValue}
                                onChange={updateForm}
                                validateChange={numericalInputValidator}
                            />,
                            <TextInput
                                key={"emergencyContactMobileNumber"}
                                name="emergencyContactMobileNumber"
                                title="Mobile Telephone Number"
                                value={currentValue}
                                onChange={updateForm}
                                validateChange={numericalInputValidator}
                            />
                        ]
                    }
                />
                <hr/>
                <h2>Health Questionnaire</h2>
                <FormGroup
                    questionTitle={'Are you currently experiencing any of the following conditions:'}
                    children={
                        HealthQuestionnaire.map((option) => {
                            return <div style={{marginBottom:40}} key={option.id}>
                                <p key={`${option.id}-question-title`}>{option.name}</p>
                                <Multiselect
                                    key={`${option.id}-multiselect`}
                                    name={option.id}
                                    value={currentValue}
                                    onChange={updateForm}
                                    children={
                                        [
                                            {"id": `${option.id}.yes`, "title": "Yes"},
                                            {"id": `${option.id}.no`, "title": "No"}
                                        ]
                                    }
                                    allowMultiple={false}
                                />
                                <TextArea
                                    key={`${option.id}Detail`}
                                    name={`${option.id}Detail`}
                                    placeholder={"Please explain futher"}
                                    value={currentValue}
                                    onChange={updateForm}
                                    isHidden={!valueContains(option.id, `${option.id}.yes`)}
                                />
                            </div>
                        })
                    }
                />
                <FormGroup
                    questionTitle={"Have you had any recent injuries or surgery?"}
                    children={
                        [
                            <Multiselect
                                key={"healthRecentInjuries"}
                                name={"healthRecentInjuries"}
                                value={currentValue}
                                onChange={updateForm}
                                children={
                                    [
                                        {"id": `healthRecentInjuries.yes`, "title": "Yes"},
                                        {"id": `healthRecentInjuries.no`, "title": "No"}
                                    ]
                                }
                                allowMultiple={false}
                            />,
                            <TextArea
                                key={"healthRecentInjuriesDescription"}
                                name={"healthRecentInjuriesDescription"}
                                placeholder={"Please explain further"}
                                value={currentValue}
                                onChange={updateForm}
                                isHidden={!valueContains('healthRecentInjuries', 'healthRecentInjuries.yes')}
                            />
                        ]
                    }
                />
                <hr/>
                <h2>Medical History</h2>
                <FormGroup
                    questionTitle={
                        "Please disclose any known diagnosed conditions or any current ongoing medical investigations." +
                        " (Eg. Asthma, Osteoarthritis, Osteoporosis, Diabetes, Cancer, Chronic Fatigue, Any Aches or Pains, " +
                        "Current Emotional Health & Wellbeing, Upcoming Medical Appointments or Awaiting Investigations…)"
                    }
                    children={
                        [
                            <TextArea
                                key={"healthMedicalHistory"}
                                name={"healthMedicalHistory"}
                                value={currentValue}
                                onChange={updateForm}
                            />
                        ]
                    }
                />
                <hr/>
                <h2>Pilates Participation Informed Consent</h2>
                <>
                    {PilatesInformationConsent().map((p, index) => <p key={`information-${index}`}>{p}</p>)}
                    <Multiselect
                        key={"informedConsent"}
                        name={"informedConsent"}
                        value={currentValue}
                        onChange={updateForm}
                        children={
                            [
                                {"id": `informedConsent.yes`, "title": "I understand and consent"}
                            ]
                        }
                        allowMultiple={true}
                    />
                </>
                <hr/>
                <h2>EMS Pilates Participant Privacy Notice Consent</h2>
                <>
                    {PrivacyNoticeConsent().map((p, index) => <p key={`privacy-notice-${index}`} dangerouslySetInnerHTML={{__html:p}}/>)}
                </>
                <div className={"footer"}>
                    <div className={"footer-center"}>
                        {!submitted && (
                            <>
                                {!submitting && (
                                    <button id={"submit"} onClick={() => {
                                        if (submitting) {
                                            return
                                        }
                                        setError(false)
                                        setSubmitting(true)
                                        axios.post(
                                            `${Settings.host}/registration`,
                                            {data: form}
                                        )
                                            .then((response) => {
                                                if (response.data.statusCode === 200) {
                                                    setSubmitted(true)
                                                } else {
                                                    setError(response.data.body)
                                                }
                                            })
                                            .catch((error) => {
                                                if (!!error.response &&
                                                    !!!error.response.data) {
                                                    setError(error.response.data)
                                                } else {
                                                    setError("There was an error submitting your form. Please try again.")
                                                }
                                            })
                                            .finally(() => {
                                                setSubmitting(false)
                                            })
                                    }}>
                                        Submit
                                    </button>
                                )}
                                {submitting && (
                                    <button id={"submit"}>
                                        Please Wait
                                    </button>
                                )}
                            </>
                        )}
                        {submitted && (
                            <p>Thanks for completing.</p>
                        )}
                    </div>
                </div>
                {!!error && (<p className={"error"}>{error}</p>)}
            </div>
        </>
    );
}

export default UpdateApp;
